import React from 'react';
import { useBuilderContext } from '../../contexts/BuilderContext';
import StyleEditor from './StyleEditor';

const PropertyEditor = () => {
    const { selectedComponent, updateComponent } = useBuilderContext();

    if (!selectedComponent) {
        return <div className="right-panel">Select a component to edit</div>;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateComponent(selectedComponent.id, { [name]: value });
    };

    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        updateComponent(selectedComponent.id, { [name]: parseInt(value, 10) });
    };

    const renderProperties = () => {
        switch (selectedComponent.type) {
            case 'Button':
                return (
                    <label>
                        Button Text:
                        <input
                            type="text"
                            name="title"
                            value={selectedComponent.title || ''}
                            onChange={handleChange}
                        />
                    </label>
                );
            case 'Input':
                return (
                    <label>
                        Placeholder:
                        <input
                            type="text"
                            name="placeholder"
                            value={selectedComponent.placeholder || ''}
                            onChange={handleChange}
                        />
                    </label>
                );
            case 'Image':
                return (
                    <label>
                        Image URL:
                        <input
                            type="text"
                            name="source"
                            value={selectedComponent.source || ''}
                            onChange={handleChange}
                        />
                    </label>
                );
            default:
                return null;
        }
    };

    return (
        <div className="right-panel">
            <h2>Properties</h2>
            <div className="property-editor">
                {renderProperties()}
                <label>
                    Top:
                    <input
                        type="number"
                        name="top"
                        value={selectedComponent.top}
                        onChange={handleNumberChange}
                    />
                </label>
                <label>
                    Left:
                    <input
                        type="number"
                        name="left"
                        value={selectedComponent.left}
                        onChange={handleNumberChange}
                    />
                </label>
                <label>
                    Width:
                    <input
                        type="number"
                        name="width"
                        value={selectedComponent.width}
                        onChange={handleNumberChange}
                    />
                </label>
                <label>
                    Height:
                    <input
                        type="number"
                        name="height"
                        value={selectedComponent.height}
                        onChange={handleNumberChange}
                    />
                </label>
                <StyleEditor />
            </div>
        </div>
    );
};

export default PropertyEditor;
import React from 'react';
import { useDrag } from 'react-dnd';

const ComponentItem = ({ component }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'component',
        item: { type: component.type },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div
            ref={drag}
            className={`component-item ${isDragging ? 'dragging' : ''}`}
        >
            {component.name}
        </div>
    );
};

export default ComponentItem;
import React, { useState, useRef, useEffect } from 'react';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';

const LivePreview = () => {
    const { components } = useBuilderContext();
    const [device, setDevice] = useState('iPhone X');
    const [scale, setScale] = useState(1);
    const containerRef = useRef(null);

    const devices = ['iPhone X', 'iPhone 8', 'Galaxy Note 8'];
    const isValidDevice = devices.includes(device);

    const renderComponent = (comp) => {
        const commonStyles = {
            ...comp.style,
            background: 'none', // Ensure no background is applied
            boxSizing: 'border-box', // Ensure padding and border do not affect width/height
        };

        switch (comp.type) {
            case 'Button':
                return <button style={commonStyles}>{comp.title || 'Click me'}</button>;
            case 'Input':
                return <input type="text" placeholder={comp.placeholder || 'Enter text'} style={commonStyles} />;
            case 'Image':
                return <img src={comp.source || 'https://via.placeholder.com/150'} alt="Component" style={{ ...commonStyles, maxWidth: '100%', maxHeight: '100%' }} />;
            default:
                return <div style={commonStyles}>{comp.type}</div>;
        }
    };


    useEffect(() => {
        const updateScale = () => {
            if (containerRef.current) {
                const containerHeight = containerRef.current.clientHeight;
                const containerWidth = containerRef.current.clientWidth;
                const deviceElement = containerRef.current.querySelector('.marvel-device');
                if (deviceElement) {
                    const deviceHeight = deviceElement.offsetHeight;
                    const deviceWidth = deviceElement.offsetWidth;
                    const scaleHeight = containerHeight / deviceHeight;
                    const scaleWidth = containerWidth / deviceWidth;
                    setScale(Math.min(scaleHeight, scaleWidth, 1));
                }
            }
        };

        updateScale();
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, [device]);

    return (
        <div className="live-preview">
            <h3>Live Preview</h3>
            <select value={device} onChange={(e) => setDevice(e.target.value)}>
                {devices.map((d) => (
                    <option key={d} value={d}>{d}</option>
                ))}
            </select>
            <div className="preview-container" ref={containerRef}>
                {isValidDevice ? (
                    <div style={{ transform: `scale(${scale})`, transformOrigin: 'center top' }}>
                        <DeviceFrameset device={device} color="black">
                            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                {components.map((comp) => (
                                    <div key={comp.id} style={{ position: 'absolute', left: comp.left, top: comp.top }}>
                                        {renderComponent(comp)}
                                    </div>
                                ))}
                            </div>
                        </DeviceFrameset>
                    </div>
                ) : (
                    <p>Invalid device selected</p>
                )}
            </div>
        </div>
    );
};

export default LivePreview;

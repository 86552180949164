import React from 'react';
import { useBuilderContext } from '../../contexts/BuilderContext';

const StyleEditor = () => {
    const { selectedComponent, updateComponent } = useBuilderContext();

    if (!selectedComponent) return null;

    const handleStyleChange = (e) => {
        const { name, value } = e.target;
        updateComponent(selectedComponent.id, {
            style: { ...selectedComponent.style, [name]: value },
        });
    };

    return (
        <div className="style-editor">
            <h3>Styles</h3>
            <label>
                Width:
                <input
                    type="text"
                    name="width"
                    value={selectedComponent.style?.width || ''}
                    onChange={handleStyleChange}
                />
            </label>
            <label>
                Height:
                <input
                    type="text"
                    name="height"
                    value={selectedComponent.style?.height || ''}
                    onChange={handleStyleChange}
                />
            </label>
            <label>
                Background Color:
                <input
                    type="color"
                    name="backgroundColor"
                    value={selectedComponent.style?.backgroundColor || '#ffffff'}
                    onChange={handleStyleChange}
                />
            </label>
        </div>
    );
};

export default StyleEditor;
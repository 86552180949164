import React, { useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { useBuilderContext } from '../../contexts/BuilderContext';
import DraggableComponent from '../DraggableComponent';

const Canvas = () => {
    const { components, addComponent } = useBuilderContext();
    const canvasRef = useRef(null);

    const [, drop] = useDrop(() => ({
        accept: 'component',
        drop: (item, monitor) => {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const offset = monitor.getClientOffset();
            const x = offset.x - canvasRect.left;
            const y = offset.y - canvasRect.top;
            addComponent(item.type, { x, y });
        },
    }));

    const combinedRef = (element) => {
        // Assign both refs
        drop(element);
        canvasRef.current = element;
    };

    useEffect(() => {
        const updateCanvasSize = () => {
            if (canvasRef.current) {
                const maxBottom = Math.max(...components.map(comp => comp.top + comp.height), 300);
                canvasRef.current.style.height = `${maxBottom}px`;
            }
        };

        updateCanvasSize();
    }, [components]);

    return (
        <div ref={combinedRef} className="canvas">
            {components.map((comp) => (
                <DraggableComponent key={comp.id} component={comp} />
            ))}
        </div>
    );
};

export default Canvas;

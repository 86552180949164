import React from 'react';
import { useDrag } from 'react-dnd';
import { useBuilderContext } from '../contexts/BuilderContext';

const DraggableComponent = ({ component }) => {
    const { selectComponent, updateComponent } = useBuilderContext();

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'canvas-component',
        item: { id: component.id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const handleClick = () => {
        selectComponent(component.id);
    };

    const handleDrag = (e) => {
        updateComponent(component.id, {
            top: e.clientY,
            left: e.clientX,
        });
    };

    const renderComponent = () => {
        switch (component.type) {
            case 'Button':
                return <button>{component.title || 'Click me'}</button>;
            case 'Input':
                return <input type="text" placeholder={component.placeholder || 'Enter text'} />;
            case 'Image':
                return <img src={component.source || 'https://via.placeholder.com/150'} alt="Component" style={{ maxWidth: '100%', maxHeight: '100%' }} />;
            default:
                return <div>{component.type}</div>;
        }
    };

    return (
        <div
            ref={drag}
            className={`draggable-component ${isDragging ? 'dragging' : ''}`}
            style={{
                top: component.top,
                left: component.left,
                width: component.width,
                height: component.height,
                ...component.style,
            }}
            onClick={handleClick}
            onDrag={handleDrag}
        >
            {renderComponent()}
        </div>
    );
};

export default DraggableComponent;
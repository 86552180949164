import React from 'react';
import ComponentItem from './ComponentItem';
import { useBuilderContext } from '../../contexts/BuilderContext';

const ComponentList = () => {
    const { availableComponents } = useBuilderContext();

    return (
        <div className="left-panel">
            <h2>Components</h2>
            <div className="component-list">
                {availableComponents.map((component, index) => (
                    <ComponentItem key={index} component={component} />
                ))}
            </div>
        </div>
    );
};

export default ComponentList;
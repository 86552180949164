import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BuilderProvider } from './contexts/BuilderContext';

ReactDOM.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <BuilderProvider>
                <App />
            </BuilderProvider>
        </DndProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
import React, { createContext, useContext, useState } from 'react';
import { availableComponents } from '../utils/componentRegistry';

const BuilderContext = createContext();

export const useBuilderContext = () => useContext(BuilderContext);

export const BuilderProvider = ({ children }) => {
    const [components, setComponents] = useState([]);
    const [selectedComponentId, setSelectedComponentId] = useState(null);

    const addComponent = (type, position) => {
        const newComponent = {
            id: Date.now(),
            type,
            top: position.y,
            left: position.x,
            width: 100,
            height: 50,
            style: {},
        };
        setComponents([...components, newComponent]);
    };

    const updateComponent = (id, updates) => {
        setComponents(
            components.map((comp) =>
                comp.id === id ? { ...comp, ...updates } : comp
            )
        );
    };

    const selectComponent = (id) => {
        setSelectedComponentId(id);
    };

    const selectedComponent = components.find(
        (comp) => comp.id === selectedComponentId
    );

    return (
        <BuilderContext.Provider
            value={{
                components,
                addComponent,
                updateComponent,
                selectComponent,
                selectedComponent,
                availableComponents,
            }}
        >
            {children}
        </BuilderContext.Provider>
    );
};
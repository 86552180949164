import React from 'react';
import LeftPanel from './components/LeftPanel/ComponentList';
import Canvas from './components/CentralArea/Canvas';
import LivePreview from './components/CentralArea/LivePreview';
import RightPanel from './components/RightPanel/PropertyEditor';
import { BuilderProvider } from './contexts/BuilderContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './styles/index.css';

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <BuilderProvider>
                <div className="app-container">
                    <LeftPanel />
                    <div className="central-area">
                        <Canvas />
                        <LivePreview />
                    </div>
                    <RightPanel />
                </div>
            </BuilderProvider>
        </DndProvider>
    );
}

export default App;